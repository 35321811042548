import React , {useEffect} from 'react';

import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './Elements/MainPage';
import Projects from './Elements/Projects/Projects';
import Detail from './Elements/Projects/Detail';
import Info from './Elements/Info/info';
import Layout from './Elements/Layout/Layout'; 


import './css/App.css';

const App = () => {

  // const { items } = useContext(ItemContext);

  useEffect(() => {

    document.body.classList.remove('loading');

    // 创建一个 MutationObserver 实例来隐藏 alpha banner
    const alphaBannerObserver = new MutationObserver((mutationsList, observer) => {
        for (let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                const alphaBanner = document.querySelector('.vAygCK-api-load-alpha-banner');
                if (alphaBanner) {
                    alphaBanner.style.display = 'none';
                    // 找到元素后停止观察
                    observer.disconnect();
                }
            }
        }
    });

    const alphaBannerConfig = { childList: true, subtree: true };
    alphaBannerObserver.observe(document.body, alphaBannerConfig);

    return () => {
        alphaBannerObserver.disconnect();
    };
}, []);

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/info" element={<Info />} />
          <Route path="/project/*" element={<Projects />} />
          <Route path="/detail/:id" element={<Detail />} />
        </Routes>
      </Layout>
    </Router>
  );
}

const AppWithProviders = () => (
      <App />
);

export default AppWithProviders;
