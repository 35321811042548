import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { records } from '../data/records';

import '../../../css/Projects.css';

const SingleView = ({ onPlay }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [isLeaving, setIsLeaving] = useState(false);
    const [album, setAlbum] = useState(null);
    const [prevAlbum, setPrevAlbum] = useState(null);
    const [nextAlbum, setNextAlbum] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const findAlbums = async () => {
            const currentIndex = records.findIndex(r => r.id === parseInt(id));
            const totalCount = records.length;
            
            const prevIndex = (currentIndex - 1 + totalCount) % totalCount;
            const nextIndex = (currentIndex + 1) % totalCount;
            
            setAlbum(records[currentIndex]);
            setPrevAlbum(records[prevIndex]);
            setNextAlbum(records[nextIndex]);
            setLoading(false);
        };

        findAlbums();
    }, [id]);

    useEffect(() => {
        if (!loading && !album) {
            navigate('/');
            return;
        }

        if (!loading && album) {
            requestAnimationFrame(() => {
                setIsVisible(true);
            });
        }
    }, [album, navigate, loading]);

    const handleClose = useCallback(() => {
        setIsLeaving(true);
        setIsVisible(false);
        setTimeout(() => {
            navigate('/project');
        }, 200);
    }, [navigate]);

    const handlePlay = useCallback(() => {
        setIsLeaving(true);
        setIsVisible(false);
        setTimeout(() => {
            navigate(`/detail/${album.id}`);
        }, 200);
    }, [navigate, album]);

    const handleTransitionEnd = useCallback((e) => {
        // const currentIndex = records.findIndex(r => r.id === album.id);
        if (e.propertyName === 'opacity' && isLeaving) {
            navigate(`/`);
        }
    }, [isLeaving, navigate]);

    const navigateToAlbum = useCallback((direction) => {
        if (!album) return;
        const currentIndex = records.findIndex(r => r.id === album.id);
        const totalCount = records.length;
        const newIndex = (currentIndex + direction + totalCount) % totalCount;
        navigate(`/project/${records[newIndex].id}`);
    }, [album, navigate]);

    if (loading || !album) return null;

    return (
        <div className='content-wrapper'>
            <div
                className={`view--single ${isVisible ? 'is-visible' : ''}`}
                onTransitionEnd={handleTransitionEnd}
            >
                <div className="single-view-content">
                    <button
                        className="control-button control-button--back"
                        onClick={handleClose}
                        aria-label="Back to album grid"
                    >
                        <i className="fas fa-arrow-left"></i>
                    </button>

                    <div className="albums-container">
                        {prevAlbum && (
                            <div className="album album--preview album--prev" onClick={() => navigateToAlbum(-1)}>
                                <img
                                    className="album__image"
                                    src={prevAlbum.cover}
                                    alt={prevAlbum.title}
                                />
                            </div>
                        )}

                        <div className="album album--main">
                            <span className="album__year">{album.year}</span>
                            {album.honor && (
                                <img 
                                    className="album__honor" 
                                    src={album.honor} 
                                    alt="Honor logo" 
                                />
                            )}

                            <img
                                className="album__image"
                                src={album.cover}
                                alt={album.title}
                            />

                            <button
                                className="control-button control-button--play"
                                onClick={handlePlay}
                                aria-label="Play album"
                            >
                                <svg className="icon icon--play">
                                    <use xlinkHref="#icon-play" />
                                </svg>
                            </button>

                            <h2 className="album__title">{album.title}</h2>
                        </div>

                        {nextAlbum && (
                            <div className="album album--preview album--next" onClick={() => navigateToAlbum(1)}>
                                <img
                                    className="album__image"
                                    src={nextAlbum.cover}
                                    alt={nextAlbum.title}
                                />
                            </div>
                        )}
                    </div>

                    <div className="nav-arrows">
                        <button
                            className="nav-arrow nav-arrow--prev"
                            onClick={() => navigateToAlbum(-1)}
                            aria-label="Previous album"
                        >
                            <i className="fas fa-chevron-left"></i>
                        </button>
                        <button
                            className="nav-arrow nav-arrow--next"
                            onClick={() => navigateToAlbum(1)}
                            aria-label="Next album"
                        >
                            <i className="fas fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(SingleView);