export const records = [
    {
        id: 1,
        cover: '/images/AbuttingTokyo/cover.jpeg',
        title: 'Abutting Tokyo',
        year: '2024',
        honor: '/images/Logo/SA24.png',
    },
    {
        id: 2,
        cover: '/images/BwO/cover.jpg',
        title: 'Body Without Organs',
        year: '2024',
    },
    {
        id: 3,
        cover: '/images/CinemaMeowdiso/cover.jpg',
        title: 'Cinema Meowdiso',
        artist: '©Xuanyang Huang and Xiaoyun Zhong',
        year: '2024',
        honor: '/images/Logo/SA24.png',
    },
    {
        id: 4,
        cover: '/images/Colearning/cover.png',
        title: 'Co-learning Flight',
        artist: 'Xiaoyun Zhong and Zhihua Zhong',
        year: 'Ongoing',
    },
    {
        id: 5,
        cover: '/images/Elevator/cover.jpg',
        title: 'The Elevator',
        artist: '©Xiaoyun Zhong and Xuanyang Huang',
        year: '2024',
    },
    {
        id: 6,
        cover: '/images/TailVirus/cover.jpg',
        title: 'Tail Virus',
        year: '2024',
    },
    {
        id: 7,
        cover: '/images/SharpSchool/cover.jpeg',
        title: 'Sharp School',
        year: 'Ongoing',
    },
    {
        id: 8,
        cover: '/images/HSD/cover.png',
        title: 'Headswap Diffusion',
        year: '2023',
    },
    
];