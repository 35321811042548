import React, { useEffect, Suspense, lazy, useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { records } from './data/records';

import '../../css/Detail.css';

// 动态导入项目组件
const projectComponents = {
    'abutting-tokyo': lazy(() => import('./AbuttingTokyo/AbuttingTokyo')),
    'BwO': lazy(() => import('./BwO/BwO')),
    'CinemaMeowdiso': lazy(() => import('./CinemaMeowdiso/CinemaMeowdiso')),
    'Colearning': lazy(() => import('./Colearning/Colearning')),
    'Elevator': lazy(() => import('./Elevator/Elevator')),
    'TailVirus': lazy(() => import('./genetic_virus/TailVirus')),
    'SharpSchool': lazy(() => import('./SharpSchool/SharpSchool')),
    'HSD': lazy(() => import('./HSD/HSD')),
};

const Detail = () => {
    const [isLoading, setIsLoading] = useState(true);
    const {id} = useParams();
    const navigate = useNavigate();
    const record = records.find(r => r.id === parseInt(id));

    useEffect(() => {
        document.body.classList.remove('loading');
        
        // 等待所有资源加载完成
        const checkResourcesLoaded = async () => {
            // 等待所有图片加载
            const images = document.getElementsByTagName('img');
            await Promise.all(Array.from(images).map(img => {
                if (img.complete) return Promise.resolve();
                return new Promise(resolve => {
                    img.onload = resolve;
                    img.onerror = resolve; // 即使加载失败也继续
                });
            }));

            // 等待所有视频加载
            const videos = document.getElementsByTagName('video');
            await Promise.all(Array.from(videos).map(video => {
                if (video.readyState >= 3) return Promise.resolve();
                return new Promise(resolve => {
                    video.oncanplay = resolve;
                    video.onerror = resolve;
                });
            }));

            setIsLoading(false);
            
        };

        // 如果文档已经加载完成，检查资源
        if (document.readyState === 'complete') {
            checkResourcesLoaded();
        } else {
            // 否则等待文档加载完成
            window.addEventListener('load', checkResourcesLoaded);
            return () => {
                window.removeEventListener('load', checkResourcesLoaded);
            };
        }
    }, []);

    const handleClose = useCallback(() => {
        console.log(`/project/${id}`);
        navigate(`/project/${id}`);
    }, [navigate, id]);

    // 根据记录的标题或ID确定要加载的组件
    const getProjectComponent = () => {
        if (id === "1") return projectComponents['abutting-tokyo'];
        if (id === "2") return projectComponents['BwO'];
        if (id === "3") return projectComponents['CinemaMeowdiso'];
        if (id === "4") return projectComponents['Colearning'];
        if (id === "5") return projectComponents['Elevator'];
        if (id === "6") return projectComponents['TailVirus'];
        if (id === "7") return projectComponents['SharpSchool'];
        if (id === "8") return projectComponents['HSD'];

        return projectComponents['abutting-tokyo'];
    };

    const ProjectComponent = getProjectComponent();

    return (
        <div>
            <div className={`project-detail ${isLoading ? 'loading' : ''}`}>
                <button
                    className="control-button-back"
                    onClick={handleClose}
                    aria-label="Back to album view"
                >
                    <i className="fas fa-arrow-left"></i>
                </button>
                <Suspense fallback={<div className="loading-indicator">Loading...</div>}>
                    <ProjectComponent record={record} />
                </Suspense>
            </div>
        </div>
    );
};

export default Detail;