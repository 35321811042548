import React, {useEffect} from 'react';

import Grid from './components/Grid';
import SingleView from './components/SingleView';
import Icons from './components/Icons';

import { Routes, Route } from 'react-router-dom';

import '../../css/Projects.css';

const Projects = () => {

    useEffect(() => {
        const handleIntersection = (entries) => {
            if (entries[0].isIntersecting) {
                const alphaBanner = document.querySelector('.vAygCK-api-load-alpha-banner');
                if (alphaBanner) {
                    alphaBanner.style.display = 'none';
                }
            }
        };

        const alphaBannerObserver = new IntersectionObserver(handleIntersection);
        const alphaBanner = document.querySelector('.vAygCK-api-load-alpha-banner');
        if (alphaBanner) {
            alphaBannerObserver.observe(alphaBanner);
        }
        return () => {
            alphaBannerObserver.disconnect();
        };
    }, []);

    const handlePlay = (record) => {
        // 不再需要维护状态，因为现在使用路由来处理页面切换
    };

    return (
        <div className='GridPage'>
            <Icons />
            <Routes>
                <Route path="/" element={<Grid />} />
                <Route path="/:id" element={<SingleView onPlay={handlePlay}/>} />
            </Routes>
        </div>
    );
};

export default Projects;