import React from 'react';
import { Link } from 'react-router-dom';
import "../../css/Layout.css";

const Layout = ({ children }) => {
    return (
        <div className="layout">
            <header className="header">
                <div className="header-container">
                    <div className="personal-info">
                        <h3>⛅️ Xiaoyun ZHONG</h3>
                    </div>
                    <div className="navigation_container">
                        <ul>
                            <li><Link to="/" className="a">Home</Link></li>
                            <li><Link to="/project" className="a">Projects</Link></li>
                            <li><Link to="/info" className="a">Info</Link></li>
                        </ul>
                    </div>
                </div>
            </header>
            
            <main className="main-content">
                {children}
            </main>

            <footer className="footer">
                <div className="footer-content">
                    <p>&copy; 2024 Cloudosed / Xiaoyun ZHONG. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Layout;