import React , {useEffect} from 'react';

import GeneticVirus from './Projects/genetic_virus/GeneticVirus';   

import "../css/MainPage.css";
// import AbuttingTokyo from './Projects/AbuttingTokyo/AbuttingTokyo';

const MainPage = () => {

    useEffect(() => {

        document.body.classList.remove('loading');

        // 创建一个 MutationObserver 实例来隐藏 alpha banner
        const alphaBannerObserver = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const alphaBanner = document.querySelector('.vAygCK-api-load-alpha-banner');
                    if (alphaBanner) {
                        alphaBanner.style.display = 'none';
                        // 找到元素后停止观察
                        observer.disconnect();
                    }
                }
            }
        });

        const alphaBannerConfig = { childList: true, subtree: true };
        alphaBannerObserver.observe(document.body, alphaBannerConfig);

        return () => {
            alphaBannerObserver.disconnect();
        };
    }, []);


    return (
        <div>
            <div >
                <GeneticVirus />
                {/* <p className="small-text" style={{marginTop: '30px', fontSize: '14px', fontFamily: 'Optima, Serif', textAlign: 'center', fontWeight: 'bold' }}>Welcome to my space, have fun please!</p> */}
            </div>
        </div>
    );


};

export default MainPage;