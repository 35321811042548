import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Album from './Album';
import { records } from '../data/records';

import '../../../css/Projects.css';

function Grid() {
  const navigate = useNavigate();
  const [, setIsLoaded] = useState(false);
  const cloudRef = useRef(null);
  const rainInterval = useRef(null);

  const handleAlbumClick = (record) => {
    navigate(`${record.id}`);
  };

  function randomText(){
    var text = ("!@#$%^*()あいうえおかきくけこ你好世界中国文化春夏秋冬龙凤呈祥")
    let letters = text[Math.floor(Math.random() * text.length)];
    return letters;
  }

  useEffect(() => {
    setIsLoaded(true);
    
    // Define rain function inside useEffect
    function rain() {
      if (!cloudRef.current) return;
      
      let e = document.createElement('div');
      e.classList.add('drop');
      cloudRef.current.appendChild(e);
    
      let left = Math.floor(Math.random() * 300);
      let size = Math.random() * 1.5;
      let duration = Math.random() * 1 + 1;
    
      e.innerText = randomText();
      e.style.left = left + 'px';
      e.style.fontSize = 0.5 + size + 'em';
      e.style.animationDuration = duration + 's';
    
      setTimeout(function(){
        if (cloudRef.current && e.parentNode === cloudRef.current) {
          cloudRef.current.removeChild(e);
        }
      }, 5000);
    }
    
    // Start the rain effect
    rainInterval.current = setInterval(rain, 20);

    // Cleanup function
    return () => {
      if (rainInterval.current) {
        clearInterval(rainInterval.current);
      }
    };
  }, []); // 移除rain依赖，因为它现在在useEffect内部

  return (
    <div className="content-wrapper">
      <div className="cloud_container">
        <div className="cloud" ref={cloudRef}>
          <h2>cloudosed</h2>
        </div>
      </div>
      <ul className="grid">
        {records.map(record => (
          <li key={record.id} className="grid__item">
            <Album 
              record={record}
              onClick={() => handleAlbumClick(record)}
              isGrid={true}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Grid;