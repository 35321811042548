import React from 'react';

const GeneticVirus = () => {

    return (
        <div style={{ 
            width: "100%", 
            height: "85vh", 
            overflow: 'hidden', 
            border: 'none',
            position: 'relative'
        }}>
            <iframe 
                src="/genetic_virus/index.html"
                title="Genetic Virus Simulation"
                style={{ 
                    width: "100%", 
                    height: "100%", 
                    overflow: 'hidden', 
                    border: 'none',
                    position: 'absolute',
                    top: 0,
                    left: 0
                }}
                scrolling="no"
            ></iframe>
        </div>
    );

};

export default GeneticVirus;