import React from 'react';
import { CLASSES } from '../constants';

import '../../../css/Projects.css';

function Album({ record, onClick, isGrid }) {
    if (!record || !record.cover) {
        console.warn('Album component received invalid record data');
        return null;
    }

    const { cover, title, artist, side1 = [], side2 = [], year, honor } = record;

    return (
        <div
            className={`album ${isGrid ? 'album--grid' : 'album--single'} ${CLASSES.VISIBLE}`}
            onClick={onClick}
        >
            <div className="album__cover">
                <img
                    src={cover}
                    alt={title}
                    className="album__image"
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/img/default-album.jpg';
                    }}
                />
                {isGrid && <div className="album__vinyl" />}
            </div>

            <div className="album__info">
                <h2 className="album__title">{title}</h2>
                <h3 className="album__artist">{artist}</h3>
                {honor && (
                    <img 
                        className={`album__honor`}
                        src={honor}
                        alt="Honor logo"
                    />
                )}
                
                {isGrid && <p className="album__timestamp">{year}</p>}

                {!isGrid && (
                    <div className="album__tracks">
                        <div className="album__side">
                            <h4>Side 1</h4>
                            <ul>
                                {side1.map((track, index) => (
                                    <li key={index} className="album__track">{track}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="album__side">
                            <h4>Side 2</h4>
                            <ul>
                                {side2.map((track, index) => (
                                    <li key={index} className="album__track">{track}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Album;