// Animation durations
export const ANIMATION = {
    RECORD_SPIN: 2000,
    PAGE_TRANSITION: 800,
    FLIP_DURATION: 1500
};

// Audio settings
export const AUDIO = {
    FADE_DURATION: 2,
    VOLUME: {
        DEFAULT: 0.7,
        MIN: 0,
        MAX: 1
    }
};

// Player states
export const PLAYER_STATE = {
    PLAYING: 'playing',
    PAUSED: 'paused',
    STOPPED: 'stopped',
    LOADING: 'loading'
};

// Record sides
export const RECORD_SIDE = {
    SIDE_1: 'side1',
    SIDE_2: 'side2'
};

// Routes
export const ROUTES = {
    HOME: '/',
    ALBUM: '/album/:id',
    PLAYER: '/player'
};

// CSS Classes
export const CLASSES = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
    HIDDEN: 'is-hidden',
    FLIPPED: 'is-flipped',
    LOADING: 'is-loading'
};